import Swiper, { Navigation, Autoplay } from 'swiper';
Swiper.use([Navigation, Autoplay]);
export default class Banner {
    constructor() {
        this.sponsoredContentSlider();
        this.featuredNewsSlider();
    }
    sponsoredContentSlider() {
        if (document.querySelector('#sponsoredContentSlider')) {
            new Swiper('#sponsoredContentSlider .swiper-container', {
                sliderPerView: 1,
                loop: true,
                autoplay: true,
                navigation: {
                    clickable: true,
                    nextEl: ".icon-chevron-right",
                    prevEl: ".icon-chevron-left",
                }
            });
        }
    }

    featuredNewsSlider() {
        if (document.querySelector('#featuredNewsSlider')) {
            new Swiper('#featuredNewsSlider .swiper-container', {
                sliderPerView: 1,
                loop: true,
                autoplay: {
                    delay: 3000,
                },
                navigation: {
                    clickable: true,
                    nextEl: ".icon-chevron-right",
                    prevEl: ".icon-chevron-left",
                }
            });
        }
    }
}
